import * as React from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  CardContent,
  Card,
  Grow,
} from '@mui/material';
import './about-me.page.css';

import me from '../../images/me.jpg';
import {
  Business,
  Face,
  Language,
  School,
  Work,
  Favorite,
  ImportantDevices,
  Memory,
  Whatshot,
  Schedule,
} from '@mui/icons-material';

export interface AboutMePageProps {}

export default class AboutMePage extends React.Component<
  AboutMePageProps,
  any
> {
  calculateAge(birthday: Date) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  render() {
    return (
      <div className="aboutMePage defaultPageLayout defaultGrid">
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent className="centerCardContent">
              <h1 className="lightSectionTitle">About Me</h1>
              <img
                src={me}
                style={{
                  width: 150,
                }}
              />

              <List>
                <ListItem>
                  <Avatar>
                    <Business />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="Company"
                    secondary="folivora.AI GmbH. (folivora are sloth and the AI is a specific kind of sloth)"
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <Face />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="CEO"
                    secondary="Andreas Hegenberg"
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <Schedule />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="Age"
                    secondary={this.calculateAge(new Date(1986, 8, 11))}
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <Language />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="Where"
                    secondary="German, living in beautiful Munich"
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <ImportantDevices />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="What"
                    secondary={
                      'Developing for iOS & macOS since 2009.' +
                      ' However I also do a lot of Android and Web stuff.'
                    }
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <Memory />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="Technologies"
                    secondary={
                      'Objective-C, Swift, C, Java, JavaScript/TypeScript (Angular, React, React Native, NodeJS), ' +
                      'Python (Django), Kotlin'
                    }
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <School />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="Education"
                    secondary={
                      'Finished my Master @ the awesome Technische Universität München, Germany' +
                      ' in 2013'
                    }
                  />
                </ListItem>

                <ListItem>
                  <Avatar>
                    <Work />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="Previous Work"
                    secondary={
                      'Worked @ Siemens AG from late 2013 to early 2018 ' +
                      '(doing research on mobile & social computing there)'
                    }
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <Whatshot />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary="Now"
                    secondary="Fully self-employed since February 2018. Founder & CEO of the folivora.ai GmbH"
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <Favorite />
                  </Avatar>
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary=""
                    secondary="Loving my wife Kathrin and our three little kids more than anything"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}
