import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import * as React from 'react';
import './header.css';
import { Home, ArrowDropDown } from '@mui/icons-material';
import withRouter, { WithRouterProps } from '../../withRouter';

class HeaderComponent extends React.Component<any> {
  state = {
    anchorEl: undefined,
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <AppBar position="static" color="inherit" elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => {
              this.props.router.navigate('/');
            }}
            size="large">
            <Home />
          </IconButton>
          <Button
            size="large"
            color="inherit"
            onClick={this.handleClick}
            aria-owns={anchorEl ? 'appmenu' : undefined}
            aria-haspopup="true"
          >
            Apps
            <ArrowDropDown>send</ArrowDropDown>
          </Button>
          <Menu
            color="primary"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            className="appmenu"
          >
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.props.router.navigate('/');
              }}
              data-href={'/'}
            >
              BetterTouchTool
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.props.router.navigate('/bettersnaptool');
              }}
              data-href={'/bettersnaptool'}
            >
              BetterSnapTool
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.props.router.navigate('/bttremote');
              }}
              data-href={'/bttremote'}
            >
              BTT Remote
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.props.router.navigate('/keyboardcleantool');
              }}
              data-href={'/keyboardcleantool'}
            >
              KeyboardCleanTool
            </MenuItem>
          </Menu>

          <Button
            size="medium"
            color="inherit"
            onClick={() => this.props.router.navigate('/buy')}
            data-href={'/buy'}
          >
            Buy
          </Button>
          <Button
            size="medium"
            color="inherit"
            data-href={'/downloads'}
            onClick={() => this.props.router.navigate('/downloads')}
          >
            Downloads
          </Button>
          <Button
            size="medium"
            color="inherit"
            data-href={'/blog'}
            onClick={() => this.props.router.navigate('/blog')}
          >
            Blog
          </Button>
          <Button
            size="medium"
            color="inherit"
            data-href={'/bugs'}
            onClick={() => window.open('https://community.folivora.ai')}
          >
            Community & Help
          </Button>

          <Button
            size="medium"
            color="inherit"
      
            onClick={() => window.open('https://community.folivora.ai/c/setup-preset-sharing/5')}
          >
           Popular Presets / Preset Sharing
          </Button>
          <Button
            size="medium"
            color="inherit"
            data-href={'/docs'}
            onClick={() => this.props.router.navigate('/docs')}
          >
            Documentation
          </Button>
          <Button
            size="medium"
            color="inherit"
            data-href={'/about-me'}
            onClick={() => this.props.router.navigate('/about-me')}
          >
            About
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(HeaderComponent);
